<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See sub group config")}：${name}`
              : this.type === "create"
              ? `${$t("application.Create sub group config")}`
              : `${$t("application.Edit sub group config")}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子站点列表项
      </v-alert>
      <v-row v-if="!isUngroup">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 站点</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            v-model="siteId"
            :items="sites"
            item-text="title"
            item-value="id"
            required
            :rules="rules.notNullRules"
            @change="siteSel(siteId)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 子站点名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            v-if="!isUngroup"
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
          <span
            v-else
            style="color: rgba(0, 0, 0, 0.6);
    font-size: 15px;"
          >
            {{ name }}</span
          >
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 子站点地址</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            v-if="!isUngroup"
            :auto-grow="true"
            rows="1"
            v-model="url"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.isurl"
            persistent-hint
            :hint="is_readonly ? '' : '如：http://www.abc.com'"
          ></v-textarea>
          <span
            v-else
            style="color: rgba(0, 0, 0, 0.6);
    font-size: 15px;"
          >
            {{ url }}</span
          >
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 站点隐藏</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-checkbox
            v-model="disable"
            :readonly="is_readonly"
            :disabled="is_readonly"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import bus from "@/util/eventBus";

export default {
  name: "SonSiteEditor",
  props: [
    "all_sites",
    "is_readonly",
    "type",
    "isUngroup",
    "sonSiteData",
    "curSonSiteIndex",
    "curGroupSiteIndex"
  ],
  data() {
    let isTrueUrl = value => {
      let reg = /(http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };

    return {
      valid: true,
      name: "",
      url: "",
      siteId: "",
      sites: [],
      disable: false,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    siteSel(siteId) {
      this.siteId = siteId;
    },
    save() {
      if (this.$refs.form.validate()) {
        let playload = {
          index: this.curGroupSiteIndex,
          idx: this.curSonSiteIndex,
          data: {
            site_id: this.siteId,
            name: this.name,
            url: this.url,
            disable: this.disable
          }
        };

        if (this.isUngroup) {
          this.$emit("editUngroupSonEvent", playload);
        } else {
          this.$emit("editSonEvent", playload);
        }

        this.$store.commit("show_sheet", { show: false });
        bus.$emit("SITE");
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let playload = {
          index: this.curGroupSiteIndex,
          data: {
            site_id: this.siteId,
            name: this.name,
            url: this.url,
            disable: this.disable
          }
        };

        this.$emit("addSonEvent", playload);
        this.$store.commit("show_sheet", { show: false });
        bus.$emit("SITE");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      if (this.sonSiteData) {
        let { site_id, name, url, disable } = this.sonSiteData;
        this.siteId = site_id;
        this.name = name;
        this.url = url;
        this.disable = disable;
      }
    },
    fetchSites() {
      if (this.all_sites) {
        this.sites = this.all_sites;
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.reset();
        }
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      } else {
        this.fetchSites();
        this.type === "edit" ? this.fetchData() : "";
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
