<template>
  <div class="datasource-container">
    <!-- OAUTH2 -->
    <v-card elevation="0" class="dense">
      <v-card-title>基本信息 <a @click="show_sheet">(修改)</a></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>站点ID：</span>
            <span
              :style="{
                color: id ? '' : '#9195a3'
              }"
            >
              {{ id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>service_id：</span>
            <span
              :style="{
                color: service_id ? '' : '#9195a3'
              }"
            >
              {{ service_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>service_type：</span>
            <span
              :style="{
                color: service_type ? '' : '#9195a3'
              }"
            >
              {{ service_type || "未填写" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>name：</span>
            <span
              :style="{
                color: name ? '' : '#9195a3'
              }"
            >
              {{ name || "未填写" }}
            </span>
          </v-col>
          <v-col> </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Component :is="sheet_editor" type="edit" :allDatas="allDatas" />
  </div>
</template>

<script>
import WebExpEditor from "@/views/application/editor/WebExp";

const EDITORS = {
  WebEXP: WebExpEditor
};

export default {
  name: "DataSource",
  props: ["allDatas"],
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      service_id: "",
      id: "",
      service_type: "",
      name: ""
    };
  },
  mounted() {
    this.getCurIdData();
  },
  methods: {
    getCurIdData() {
      if (this.allDatas) {
        let { service_id, id, service_type, name } = this.allDatas;
        this.service_id = service_id;
        this.id = id;
        this.service_type = service_type;
        this.name = name;
      }
    },
    show_sheet() {
      this.sheet_editor = EDITORS[this.service_type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  watch: {
    allDatas() {
      this.getCurIdData();
    }
  },
  components: {
    WebExpEditor
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
