var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datasource-container"},[_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("基本信息 "),_c('a',{on:{"click":_vm.show_sheet}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("站点ID：")]),_c('span',{style:({
              color: _vm.id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("service_id：")]),_c('span',{style:({
              color: _vm.service_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.service_id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("service_type：")]),_c('span',{style:({
              color: _vm.service_type ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.service_type || "未填写")+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("name：")]),_c('span',{style:({
              color: _vm.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.name || "未填写")+" ")])]),_c('v-col'),_c('v-col')],1)],1)],1),_c(_vm.sheet_editor,{tag:"Component",attrs:{"type":"edit","allDatas":_vm.allDatas}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }