<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ `子站点排序：${name}` }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子站点排序列表项
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 组列表</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-select
            v-model="groupId"
            :items="all_groups"
            item-text="name"
            item-value="id"
            @change="orderGourpSel(groupId)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 顺序号</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-text-field
            v-model="order"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules && rules.rightOrider"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import bus from "@/util/eventBus";

export default {
  name: "SonSiteEditor",
  props: [
    "expApplications",
    "is_readonly",
    "type",
    "sonSiteData",
    "curSonSiteIndex",
    "curGroupSiteIndex"
  ],
  data() {
    let orderNum = v => {
      let reg = /^[0-9]*[1-9][0-9]*$/;
      return v && reg.test(v) && !(Number(v) > this.curGroupLinkLen);
    };
    return {
      valid: true,
      order: "1",
      groupId: "",
      all_groups: [],
      curGroupLinkLen: 0,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        rightOrider: [v => orderNum(v) || "请输入合理的排序号"]
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    orderGourpSel(groupId) {
      this.groupId = groupId;
      this.curGroupLinkLen = this.all_groups[this.groupId].links.length;
    },
    save() {
      if (this.$refs.form.validate()) {
        let playload = {
          preIndex: this.curGroupSiteIndex,
          preIdx: this.curSonSiteIndex,
          index: this.groupId,
          idx: this.order,
          data: this.sonSiteData
        };
        this.$emit("orderSonEvent", playload);
        this.$store.commit("show_sheet", { show: false });
        bus.$emit("SITE");
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      if (this.expApplications) {
        this.all_groups = this.expApplications.map((item, index) => {
          item.name = item.name ? item.name : "门户站点";
          item.id = index;
          return item;
        });
      }

      this.groupId = this.curGroupSiteIndex;
      this.curGroupLinkLen = this.all_groups[this.groupId].links.length;

      let { name } = this.sonSiteData;
      this.name = name;
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          this.reset();
        }
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      } else {
        this.type === "edit" ? this.fetchData() : "";
        this.order = "1";
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
