<template>
  <div class="linkorigin-wrapper">
    <Breadcrumbs />
    <div class="content-wrapper">
      <TabList @tabEvent="tabEvent($event)" :type="type" :tabList="tabList">
        <template v-slot:action-buttons v-if="tabId === 1">
          <v-btn
            depressed
            color="primary"
            @click.stop="$refs.component.addGroupSite()"
            style="margin-right:20px"
            >新增组站点</v-btn
          >
          <v-btn
            depressed
            color="primary"
            @click.stop="$refs.component.saveSort()"
            >保存</v-btn
          >
        </template>
      </TabList>
      <component :is="curCom" :allDatas="allDatas" ref="component" />
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import DataSource from "./component/DataSource";
import SiteManage from "./component/SiteManage";
import bus from "@/util/eventBus";
import { mapMutations } from "vuex";

const COMPONENTS = [DataSource, SiteManage];

export default {
  name: "LinkOrigin",
  data() {
    return {
      tabId: 0,
      type: null,
      allDatas: {},
      curCom: ""
    };
  },
  mounted() {
    this.getCurIdData(this.$route.params.id);
    bus.$off("refreshOrigin").$on("refreshOrigin", () => {
      this.getCurIdData(this.$route.params.id);
    });
  },
  methods: {
    ...mapMutations(["change_breadcrumbs"]),
    tabEvent(id) {
      this.tabId = id;
    },
    getCurIdData(id) {
      this.$http
        .get(`api/application/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.type = data.service_type;
          this.allDatas = data;
          this.change_breadcrumbs([
            { text: "Site Management" },
            { text: data.service_type }
          ]);
          this.curCom = COMPONENTS[this.tabId];
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  watch: {
    tabId(v) {
      this.curCom = COMPONENTS[v];
    }
  },
  computed: {
    tabList() {
      if (!this.type) return [];
      let arr = [
        {
          id: 0,
          name: "站点信息"
        },
        {
          id: 1,
          name: "站点管理"
        }
      ];
      return arr;
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    DataSource,
    SiteManage
  }
};
</script>

<style lang="less" scoped>
.linkorigin-wrapper {
  .main-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 27px;
    margin-bottom: 30px;
  }
}
</style>
