<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("application.See group config")}：${name}`
              : this.type === "create"
              ? `${$t("application.Create group config")}`
              : `${$t("application.Edit group config")}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        组站点列表项
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 组站点名称</v-subheader>
        </v-col>
        <v-col cols="9" class="py-0">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create() : save()"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import bus from "@/util/eventBus";

export default {
  name: "GroupSiteditor",
  props: ["type", "groupSiteData", "curGroupSiteIndex", "is_readonly"],
  data() {
    let isTrueUrl = value => {
      let reg = /(http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      name: "",
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      }
    };
  },
  created() {},
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("editGroupEvent", {
          editGroup: {
            index: this.curGroupSiteIndex,
            data: { name: this.name }
          }
        });
        bus.$emit("SITE");
        this.$store.commit("show_sheet", { show: false });
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        this.$emit("addGroupEvent", { name: this.name, links: [] });
        bus.$emit("SITE");
        this.$store.commit("show_sheet", { show: false });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      if (this.groupSiteData) {
        let { name } = this.groupSiteData;
        this.name = name ? name : "门户站点";
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
      }
    }
  },
  watch: {
    show_sheet(v) {
      if (v === false) {
        this.$refs.form.reset();
      } else {
        this.type === "edit" ? this.fetchData() : "";
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.65);
}

/deep/ .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
